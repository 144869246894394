import React from 'react'
import styled, { css } from 'styled-components'
import { required, phone, email, } from '../utils/validators'

export const contactForm = {
    fields: [
        {
            type: 'text',
            name: 'firstName',
            placeholder: 'First Name',
            validator: required,
            errorMessage: 'This field is required',
        },
        {
            type: 'text',
            name: 'lastName',
            placeholder: 'Last Name',
            validator: required,
            errorMessage: 'This field is required',
        },
        {
            type: 'text',
            name: 'businessName',
            placeholder: 'Business Name',
            validator: required,
            errorMessage: 'This field is required',
        },
        {
            type: 'text',
            name: 'abn',
            placeholder: 'ABN',
        },
        {
            type: 'text',
            name: 'businessAddress',
            placeholder: 'Business Address',
        },
        {
            type: 'text',
            name: 'phone',
            placeholder: 'Phone',
            validator: phone,
            errorMessage: 'Please enter a valid phone number',
        },
        {
            type: 'email',
            name: 'email',
            placeholder: 'Email Address',
            validator: email,
            errorMessage: 'Please enter a valid email address',
        },
    ]
}
